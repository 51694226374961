function dataFormate(params) {
    for (let key in params) {
        let intParam = parseInt(params[key]);

        // 检查转换结果是否为有效的整数
        if (!isNaN(intParam)) {
            // 参数是有效的整数
            // console.log("参数类型为整数:", intParam);
            params[key] = intParam;
        } else {
            // 参数不是有效的整数
            // console.log("参数不是有效的整数");
        }
    }
    return params;
}

function recursiveJsonParse(jsonString) {
    const parsedData = JSON.parse(jsonString, (key, value) => {
        if (typeof value === 'string') {
            try {
                return JSON.parse(value);
            } catch (error) {
                return value;
            }
        }
        return value;
    });

    return parsedData;
}

export {
	dataFormate, recursiveJsonParse
}
