// import Vue from "vue";
// import Vuex from "vuex";
// // import getters from "./getters";
// import home from "./modules/home";

// Vue.use(Vuex);

// const store = new Vuex.Store({
//   modules: {
//     home,
//   },
//   // getters,
// });

// export default store;

import Vue from "vue";
import Vuex from "vuex";
import localData from "@/local_data/data.json";

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		dashboard: {}
	},
	mutations: {
    GETDATA(state, data) {
      state.dashboard = data;
    },
	},
	actions: {
		async getData({ commit }) {
      const { data: res } = await Vue.prototype.$http.get("web_api/dashboard/home");
      console.log(res.data)
      commit("GETDATA", res.data);
    },
	}
})

export default store