import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from '../components/Login.vue'
// import Home from '../components/Home.vue'
// import Welcome from '../components/Welcome.vue'

// import Users from '../components/user/User.vue'
// import Rights from '../components/power/Rights.vue'
// import Roles from '../components/power/Roles.vue'

// import Cate from '../components/goods/Cate.vue'
// import Params from '../components/goods/Params.vue'

// import GoodsList from '../components/goods/List.vue'
// import Add from '../components/goods/Add.vue'

// import Order from '../components/order/Order.vue'
// import Report from '../components/report/Report.vue'

// 路由懒加载
const Login = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Login.vue')
const Home = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Home.vue')
const Welcome = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Dashboard/index.vue')


const Admin = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/admin/List.vue')
// const Rights = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/power/Rights.vue')
const Roles = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/admin/Roles.vue')


// const Order = () => import(/* webpackChunkName: "Order_Report" */ '../components/order/Order.vue')
const Report = () => import(/* webpackChunkName: "Order_Report" */ '../components/datas/Report.vue')
const Transaction = () => import(/* webpackChunkName: "Order_Report" */ '../components/datas/Transaction.vue')
const Refund = () => import(/* webpackChunkName: "Order_Report" */ '../components/datas/Refund.vue')

/** 资讯模块 start */
const NewsList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/news/List.vue')
const AddNews = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/news/Add.vue')
/** 资讯模块 end */


/**服务模块-start */
const ServiceList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/serviceManage/service/List.vue')
const AddService = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/serviceManage/service/Add.vue')
const MachinesList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/serviceManage/machines/List.vue')
const AddMachine = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/serviceManage/machines/Add.vue')
const DriverList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/serviceManage/drivers/List.vue')
const ServiceOrderList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/serviceManage/order/ServiceOrder.vue')
/**服务模块-end */

/**商城模块-start */

const GoodsList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/shopManage/goods/List.vue')
const Add = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/shopManage/goods/Add.vue')
const ShopOrderList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/shopManage/order/ShopOrder.vue')
const SupplierList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/shopManage/suppliers/List.vue')
/**商城模块-end */

/**农场模块-start */
const FarmsList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/farms/List.vue')
const AddFarm = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/farms/Add.vue')
const FarmOrderList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/order/FarmOrder.vue')
const FarmProductList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/products/List.vue')
const AddFarmProduct = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/products/Add.vue')

const FarmFoodList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/foods/List.vue')
const AddFarmFood = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/foods/Add.vue')
const FoodOrderList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/farmManage/order/FoodOrder.vue')
/**农场模块-end */

const Members = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/members/List.vue')

/** 字典管理 start */
const Cate = () => import(/* webpackChunkName: "Cate_Params" */ '../components/dictionary/Cate.vue')
const Params = () => import(/* webpackChunkName: "Cate_Params" */ '../components/dictionary/Params.vue')
/** 字典管理 end */

/** 土地租赁 start */
const Lands = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/LandLease/land/List.vue')
const leaseRecords = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/LandLease/records/List.vue')
/** 土地租赁 end */

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/home',
    component: Home,
    redirect: '/Welcome',
    children: [
      { path: '/home', component: Welcome },
      { path: '/admins', component: Admin },
      // { path: '/rights', component: Rights },
      { path: '/roles', component: Roles },
      { path: '/categories', component: Cate },
      { path: '/params', component: Params },
      { path: '/goods', component: GoodsList },
      { path: '/goods/add', component: Add },
      // { path: '/orders', component: Order },
      { path: '/reports', component: Report },
	    { path: '/transactions', component: Transaction },
      { path: '/refunds', component: Refund },
      { path: '/machines', component: MachinesList },
      { path: '/machines/add', component: AddMachine },
      { path: '/farms', component: FarmsList },
      { path: '/farms/add', component: AddFarm },
      { path: '/news', component: NewsList },
      { path: '/news/add', component: AddNews },
      { path: '/drivers', component: DriverList },
      { path: '/services', component: ServiceList },
      { path: '/services/add', component: AddService },
      { path: '/orders_service', component: ServiceOrderList },
      { path: '/orders_shop', component: ShopOrderList },
      { path: '/orders_farm', component: FarmOrderList },
      { path: '/suppliers', component: SupplierList },
      { path: '/products_farm', component: FarmProductList },
      { path: '/farms/product_add', component: AddFarmProduct },
      { path: '/members', component: Members },
      { path: '/lands', component: Lands},
      { path: '/leaseRecords', component: leaseRecords },
      { path: '/farms_foods', component: FarmFoodList },
      { path: '/farms/food_add', component: AddFarmFood },
      { path: '/orders_food', component: FoodOrderList },
    ] }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
	 next()
	 return;
  // 访问登录页，放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token, 强制跳转到登录页
  if (!tokenStr) return next('/login')
  next()
})

export default router
